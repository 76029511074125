import React from 'react';
import styled from 'styled-components';
import { typography, pageMargins } from '../../../../shared/styles';
import TextContainer from './TextContainer';
import ImageCarousel from '../../../globals/ImageCarousel';

const isOdd = num => {
  return num % 2;
};

const StyledImageGallery = styled.div`
  width: 100%;
  display: flex;
  ${pageMargins}
  margin: 4.5rem 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${props => (isOdd(props.index + 1) ? 'row' : 'row-reverse')};
  padding-top: 3.5rem;
  & h2 {
    font-size: ${typography.size.l1};
    font-family: ${typography.type.primary};
  }
  & p {
    font-family: ${typography.type.secondary};
    font-weight: ${typography.weight.regular};
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }
  & a {
    text-decoration: none;
  }
  & .gallery__text-container {
    width: 45%;
    & h6 {
      font-family: ${typography.type.secondary};
      font-weight: ${typography.weight.bold};
      text-transform: uppercase;
      font-size: ${typography.size.s1};
      line-height: 1.5rem;
    }
    p {
      margin-bottom: 3rem;
    }
  }
  &:last-of-type {
    margin: 0 0 5rem 0;
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    margin: 3.2rem 0 5.8rem;

    & h2 {
      margin-top: 2rem;
      font-size: ${typography.size.m3};
      margin-bottom: 1rem;
    }
    & p {
      font-size: 1rem;
      line-height: 1.5em;
    }
    & .gallery__text-container {
      width: 100%;
    }
  }

  @media only screen and (min-width: 1441px) {
    & h2 {
      width: 75%;
    }
    & p {
      max-width: 75%;
    }
  }
`;

const ImageGallery = ({ title, description, page, link, images, index, uid, name }) => {
  const classTitle = name ? name.toLowerCase().replace(/\s/g, '-') : '';
  return (
    <StyledImageGallery index={index}>
      <ImageCarousel
        size={uid === 'developments' ? 'l' : 's'}
        captions={!page ? images.map(item => item.image_captions.text) : []}
        images={images.map(item => item.image)}
        name={classTitle}
      />
      <TextContainer
        name={name}
        title={uid === 'asset-management' ? 'See More' : title}
        description={description}
        link={link}
        uid={uid}
      />
    </StyledImageGallery>
  );
};

export default ImageGallery;
