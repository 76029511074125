import React from 'react';
import { graphql, Link } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import { RichText } from 'prismic-reactjs';
import Layout from '../components/layout';
import { color } from '../shared/styles';
import { ImageGallery, Text, ImageTextCta, DevelopmentsText } from '../components/slices/pages';

import ImageText from '../components/projects/ImageText';

import HeroSection from '../components/HeroSection';
import Description from '../components/pages/home/Description';
import DevelopmentsGallery from '../components/slices/pages/DevelopmentsGallery';
import LogoCollection from '../components/globals/LogoCollection';

export const data = graphql`
  query($uid: String!) {
    allPrismicProjectTemplate {
      nodes {
        uid
      }
    }
    prismicPageTemplate(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPageTemplateBodyImageAndText {
            id
            primary {
              image {
                url
                thumbnails
                alt
                dimensions {
                  width
                  height
                }
              }
              text {
                html
                text
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicPageTemplateBodyImageGallery {
            id
            slice_type
            slice_label
            primary {
              link {
                uid
              }
              title {
                html
                text
              }
              description_of_the_gallery {
                html
                text
              }
              name_of_the_gallery {
                html
                text
              }
            }
            items {
              image {
                url
                thumbnails
                alt
                dimensions {
                  width
                  height
                }
              }
              image_captions {
                html
                text
              }
            }
          }
          ... on PrismicPageTemplateBodyImageTextAndButton {
            id
            slice_type
            slice_label
            items {
              button_link {
                uid

                document {
                  ... on PrismicPageTemplate {
                    id
                    uid
                  }
                }
              }
              button_text {
                text
                html
              }
            }
            primary {
              description {
                html
                text
              }
              image {
                url
                thumbnails
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageTemplateBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                text
                html
              }
            }
          }
          ... on PrismicPageTemplateBodyDevelopmentsTextAndDoubleImage {
            id
            primary {
              text {
                html
                text
              }
            }
            slice_label
            slice_type
            items {
              image {
                url
                thumbnails
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageTemplateBodyLogoCollection {
            id
            primary {
              title {
                html
                text
              }
            }
            items {
              logo {
                url
              }
            }
            slice_label
            slice_type
          }
        }
        page_header {
          html
          text
        }
        page_title {
          html
          text
        }
        page_subheader {
          text
          html
        }
        hero_image {
          url
        }
      }
      uid
    }
  }
`;

const Page = ({ data }) => {
  const cms = data.prismicPageTemplate.data;
  const uid = data.prismicPageTemplate.uid;

  const sliceRenderer = (slice, index) => {
    switch (slice.slice_type) {
      case 'developments_text_and_double_image':
        return <DevelopmentsText slice={slice} />;
      case 'image__text_and_button':
        return (
          <ImageTextCta
            description={slice.primary.description}
            buttons={slice.items}
            image={slice.primary.image}
            index={index}
          />
        );
      case 'image_gallery':
        return uid === 'developments' || uid === 'about' ? (
          <DevelopmentsGallery
            title={slice.primary.title}
            description={slice.primary.description_of_the_gallery.html}
            images={slice.items}
            name={slice.primary.name_of_the_gallery.html}
            uid={uid}
          />
        ) : (
          <ImageGallery
            title={slice.primary.name_of_the_gallery.text}
            description={slice.primary.description_of_the_gallery.html}
            link={slice.primary.link.uid}
            images={slice.items}
            name={slice.primary.name_of_the_gallery.text}
            index={index}
            uid={uid}
          />
        );
        break;
      case 'image_and_text':
        return <ImageText page imgUrl={slice.primary.image.url} text={slice.primary.text.text} />;
        break;

      case 'text':
        return slice.primary.text.text.length ? (
          <Description html={slice.primary.text.html} page />
        ) : (
          ''
        );

      case 'logo_collection':
        return <LogoCollection title={slice.primary.title} logos={slice.items} />;
        break;

      default:
        return null;
    }
  };

  return (
    <Layout bgColor={color.mainBg}>
      <HeroSection header={cms.page_header.html} bg={cms.hero_image.url} page />
      <Description html={cms.page_subheader.html} page />
      {cms.body.map((slice, i) => sliceRenderer(slice, i))}
    </Layout>
  );
};

export default withPreview(Page);
