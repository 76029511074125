import React from 'react';
import styled from 'styled-components';
import { typography, pageMargins } from '../../../shared/styles';
import parse from 'html-react-parser';

const StyledDescription = styled.div`
  ${pageMargins}
  //height: ${({ page }) => (page ? '80vh' : '100vh')};
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;

  & p {
    font-size: ${typography.size.m2};
    line-height: 2rem;
    font-family: ${typography.type.primary};
    width: 36.25rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  @media only screen and (max-width: 640px) {
    //height: 500px;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    & p {
      font-size: ${typography.size.m2};
      line-height: 24px;
      width: 100%;

      &:nth-child(2) {
        font-family: ${typography.type.secondary};
        font-size: ${typography.size.s2};
        line-height: 1.5em;
      }
    }
  }
`;

const Description = ({ html, page }) => {
  if (html.length) {
    return <StyledDescription page={page}>{parse(html)}</StyledDescription>;
  } else {
    return <div></div>;
  }
};

export default Description;
