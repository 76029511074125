import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { StyledLink } from '../../../../shared/StyledButton';
import ArrowGallery from '../../../../images/svgs/ArrowGallery';
import parse from 'html-react-parser';

const TextContainer = ({ link, links, title, description, name, uid }) => {
  const {
    allPrismicProjectTemplate: { nodes: projects },
  } = useStaticQuery(graphql`
    {
      allPrismicProjectTemplate {
        nodes {
          uid
        }
      }
    }
  `);
  console.log('uid', uid);

  const uids = projects.map(project => project.uid);
  return (
    <div className="gallery__text-container">
      <h2>{name}</h2>
      {parse(description)}
      {uid !== 'investments' ? (
        <React.Fragment>
          {!links ? (
            <StyledLink
              to={`${uids.includes(link) ? '/projects' : ''}/${link}`}
              svg
              borderColor="black"
            >
              {title}
              <ArrowGallery className="button__arrow" direction="right" />
            </StyledLink>
          ) : (
            links.map(({ button_link, button_text }) => (
              <StyledLink
                to={`${uids.includes(button_link.uid) ? '/projects' : ''}/${link.button_link.uid}`}
                svg
                borderColor="black"
              >
                {button_text.text}
                <ArrowGallery className="button__arrow" direction="right" />
              </StyledLink>
            ))
          )}
        </React.Fragment>
      ) : (
        ''
      )}
    </div>
  );
};

export default TextContainer;
