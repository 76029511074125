import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import { typography, pageMargins } from '../../../shared/styles';
import ImageCarousel from '../../globals/ImageCarousel';
import StyledButton from '../../../shared/StyledButton';
import ArrowGallery from '../../../images/svgs/ArrowGallery';

const isOdd = num => {
  return num % 2;
};

const StyledImageText = styled.div`
  width: 100%;
  display: flex;
  ${pageMargins}
  margin: 4.5rem 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ index }) => (isOdd(index + 1) ? 'row' : 'row-reverse')};
  & .description-cta__container {
    width: 45%;
  }

  & .description-cta__text {
    max-width: 75%;
  }
  & h2 {
    font-size: ${typography.size.l1};
    font-family: ${typography.type.primary};
  }
  & p {
    font-family: ${typography.type.secondary};
    font-weight: ${typography.weight.regular};
    font-size: ${typography.size.s2};
    line-height: 1.5em;
    margin-bottom: 2rem;
  }
  & a {
    text-decoration: none;
  }

  &:last-of-type {
    margin: 0 0 5rem 0;
  }
  button {
    margin: 1rem 0;
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    margin-bottom: 5rem;
    & h2 {
      margin-top: 2rem;
      font-size: ${typography.size.m3};
      margin-bottom: 1rem;
    }
    & p {
      font-size: ${typography.size.s3};
      line-height: 1.5em;
    }
    & .gallery__text-container {
      width: 100%;
    }
    & .description-cta__container {
      width: 100%;
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    & .description-cta__text {
      max-width: 100%;
    }
  }
`;

const ImageTextCta = ({ description, buttons, image, index }) => {
  const scrollToElement = elem => {
    window.scrollTo({
      top: document.querySelector(elem).offsetTop - 60,
      behavior: 'smooth',
    });
  };

  const {
    allPrismicProjectTemplate: { nodes: projects },
  } = useStaticQuery(graphql`
    {
      allPrismicProjectTemplate {
        nodes {
          uid
        }
      }
    }
  `);
  const uids = projects.map(project => project.uid);

  return (
    <StyledImageText index={index}>
      <ImageCarousel size="s" images={[image]} />
      <div className="description-cta__container">
        <div className="description-cta__text">{parse(description.html)}</div>
        {buttons.map((link, i) =>
          link.button_link.uid ? (
            <Link
              to={`${uids.includes(link.button_link.uid) ? '/projects' : ''}/${
                link.button_link.uid
              }`}
            >
              <StyledButton borderColor="black" svg>
                {link.button_text.text}

                <ArrowGallery direction="right" />
              </StyledButton>
            </Link>
          ) : (
            <StyledButton
              borderColor="black"
              svg
              onClick={() =>
                scrollToElement(
                  link.button_text.text === 'Partnerships'
                    ? `.partnerships`
                    : `.${link.button_text.text.toLowerCase().replace(/\s/g, '-')}`,
                )
              }
            >
              {link.button_text.text}
              <ArrowGallery direction="right" />
            </StyledButton>
          ),
        )}
      </div>
    </StyledImageText>
  );
};

export default ImageTextCta;
