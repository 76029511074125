import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { typography } from '../../../shared/styles';
import Container from '../../../shared/Container';
import CentrelandLogoBW from '../../../images/svgs/CentrelandLogoBW';

const StyledLogoCollection = styled.div`
  margin: 4.5rem 0;

  & h2 {
    text-align: center;
    font-size: ${typography.size.l1};
    font-family: ${typography.type.primary};
    font-weight: ${typography.type.regular};
    margin-bottom: 7.75rem;
  }
  & .logos {
    width: 100%;
    text-align: center;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
  }
  & .logos__item {
    text-align: center;
    display: flex;
    align-items: center;
    width: 20%;
    padding: 1.5rem;

    & img {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 640px) {
    & h2 {
      margin-bottom: 4rem;
    }
    & .logos {
      display: block;
    }
    & .logos__item {
      width: 100%;
      align-items: center;
      justify-content: center;
      max-width: 8rem;
      margin: 0 auto 4.4rem auto;
      padding: 0;
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 1024px) {
    & .logos {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: start;
    }
    & .logos__item {
      flex: 1 0 auto;
      width: 50%;
      & img {
        max-width: 14.2rem;
      }
    }
  }
`;

const LogoCollection = ({ title, logos }) => {
  return (
    <StyledLogoCollection>
      <Container>
        {title.html ? parse(title.html) : <h2>Partners and tenants include</h2>}
        <ul class="logos">
          {logos &&
            logos.map((item, i) => (
              <li className="logos__item">
                <img key={i} src={item.logo.url} alt="partner logo" className="logos__image" />
              </li>
            ))}
        </ul>
      </Container>
    </StyledLogoCollection>
  );
};

export default LogoCollection;
